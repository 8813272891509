import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
  faCircleQuestion,
  faCircleExclamation
} from '@fortawesome/free-solid-svg-icons';

import { markAlertAsViewed } from 'services/alerts';

import { useAlerts } from 'hooks/useAlerts';

import ImageComponent from '@ui/components/Image';
import { Label } from '@ui/components/Typography';

const Image = styled(ImageComponent)`
  width: 38px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 22px;
  color: #28c5f1;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: ${(props) => (props.isviewed ? 'var(--grey-10)' : 'var(--white)')};
  padding: 12px 24px;
  cursor: pointer;
  width: 100%;
  align-items: center;
  border-radius: 16px;
`;

const Description = styled.span`
  padding: 0 18px;
  text-align: left;
  width: 100%;
  font-size: 13px;
`;

const ErrorMessage = styled.span`
  font-size: 14px;
`;

function AlertCard({ type, metadata, isViewed, alertId }) {
  const { reloadAlerts } = useAlerts();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClick = useCallback(async () => {
    switch (type) {
      case 'REFERRAL_REWARDS':
        navigate('/referral');
        break;
      case 'TEXT':
        break;
      default:
        console.log('Action not implemented = ', type);
        break;
    }

    if (alertId) {
      await markAlertAsViewed(alertId);
      await reloadAlerts();
    }
  }, [type, alertId, navigate, reloadAlerts]);

  const logo = useMemo(() => {
    switch (type) {
      case 'REFERRAL_REWARDS':
        return 'credit-card';
      case 'TEXT':
        return 'credit-card';
      case 'PASSPORT':
        return 'passport';
      case 'INFO':
        return null;
      case 'QUESTION':
        return null;
      default:
        return '';
    }
  }, [type]);

  const icon = useMemo(() => {
    switch (type) {
      case 'INFO':
        return faCircleInfo;
      case 'QUESTION':
        return faCircleQuestion;
      default:
        return '';
    }
  }, [type]);

  const title = useMemo(() => {
    switch (type) {
      case 'PASSPORT':
        if (metadata.type === 'PASSPORT_OFFER_REQUEST_SUCCESS') {
          return 'Thank you. We will be in touch to coordinate the next steps';
        }
        break;
      case 'REFERRAL_REWARDS':
        if (metadata.rewardType === 'USER_REGISTER') {
          return (
            <div>
              You received <b>£{metadata.rewardDetails.amount}</b> because{' '}
              <b>{metadata.referredUserEmail}</b> used your referral link to register to Upscore!
            </div>
          );
        }
        break;
      default:
        return metadata?.text;
    }
  }, [type, metadata]);

  const errorMessage = useMemo(() => {
    switch (type) {
      default:
        return '';
    }
  }, [type]);

  if (!title) {
    return null;
  }

  return (
    <CardWrapper isviewed={isViewed} onClick={onClick}>
      {logo ? <Image name={logo} /> : null}
      {icon ? <Icon icon={icon} className="fa-regular" /> : null}
      <Description>
        <div>
          <Label variant="label-m">{t(title)}</Label>
        </div>
        {errorMessage ? (
          <ErrorMessage className="text-danger">
            <FontAwesomeIcon icon={faCircleExclamation} className="fa-regular" /> {t(errorMessage)}
          </ErrorMessage>
        ) : null}
      </Description>
    </CardWrapper>
  );
}

export default AlertCard;
