import React, { useContext, useState, useCallback } from 'react';
import reactUseWebSocket, { ReadyState } from 'react-use-websocket';

import { useUserSelector } from 'hooks/useUser';

const WebSocketContext = React.createContext(null);

export function WebSocketContextProvider({ children }) {
  const [handlers, setHandlers] = useState({});
  const { user } = useUserSelector();

  const onMessage = useCallback(
    ({ data }) => {
      try {
        const jsonData = JSON.parse(data);

        if (handlers[jsonData.topic]) {
          if (typeof handlers[jsonData.topic] === 'function') {
            handlers[jsonData.topic](jsonData.payload);
          } else {
            Object.keys(handlers[jsonData.topic]).forEach((location) => {
              handlers[jsonData.topic][location]?.(jsonData.payload);
            });
          }
        }

        if (handlers[jsonData.type]) {
          if (typeof handlers[jsonData.type] === 'function') {
            handlers[jsonData.type](jsonData.topic, jsonData.payload);
          } else {
            Object.keys(handlers[jsonData.type]).forEach((location) => {
              handlers[jsonData.type][location]?.(jsonData.topic, jsonData.payload);
            });
          }
        }
      } catch (e) {
        console.error();
      }
    },
    [handlers]
  );

  const onDataRefreshMessage = useCallback(
    (topic, callback) => {
      if (!handlers[topic]) {
        setHandlers({ ...handlers, [topic]: callback });
      }
    },
    [handlers, setHandlers]
  );

  const onServerSideEvent = useCallback(
    (callback) => {
      if (!handlers['server_side_event']) {
        setHandlers({ ...handlers, server_side_event: callback });
      }
    },
    [handlers, setHandlers]
  );

  const registerTopic = useCallback(
    (topic, location, callback) => {
      handlers[topic] = handlers[topic] || {};
      handlers[topic][location] = callback;

      setHandlers({ ...handlers });
    },
    [handlers, setHandlers]
  );

  const unRegisterTopic = useCallback(
    (topic, location) => {
      handlers[topic] = handlers[topic] || {};
      delete handlers[topic][location];

      setHandlers({ ...handlers });
    },
    [handlers, setHandlers]
  );

  const { readyState, sendMessage } = reactUseWebSocket(
    user?.user_id ? process.env.REACT_APP_WS_URL : null,
    {
      queryParams: {
        user_id: user?.user_id
      },
      onMessage
    }
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
  }[readyState];

  return (
    <WebSocketContext.Provider
      value={{
        sendMessage,
        connectionStatus,
        registerTopic,
        unRegisterTopic,
        onDataRefreshMessage,
        onServerSideEvent
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
}

export function useWebSocket() {
  const context = useContext(WebSocketContext);

  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketContextProvider');
  }

  return context;
}
