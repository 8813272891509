import { Navigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { useUserSelector } from 'hooks/useUser';
import { Helmet } from 'react-helmet';

export function ProtectedRoute({ children, requiredProfileCompleted }) {
  const { isLoggedIn, isLoading, isOnboardingPersonalDataCompleted } = useUserSelector();

  if (!isLoggedIn && !isLoading) {
    return <Navigate to="/login" replace />;
  }
  if (isLoading) {
    return <Spinner animation="grow" variant="info" size="lg" />;
  }
  if (!isOnboardingPersonalDataCompleted && requiredProfileCompleted) {
    return <Navigate to="/profile/intro" replace />;
  }

  return (
    <>
      <Helmet>
        <title>Upscore</title>
        <description></description>
      </Helmet>

      {children}
    </>
  );
}
