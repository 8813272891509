import { ReactComponent as AddIcon } from './assets/add.svg?react';
import { ReactComponent as ArrowLeftIcon } from './assets/arrow--left.svg?react';
import { ReactComponent as ArrowDownLeftIcon } from './assets/arrow--down-left.svg?react';
import { ReactComponent as ArrowDownRightIcon } from './assets/arrow--down-right.svg?react';
import { ReactComponent as ArrowUpLeftIcon } from './assets/arrow--up-left.svg?react';
import { ReactComponent as ArrowUpRightIcon } from './assets/arrow--up-right.svg?react';
import { ReactComponent as BankIcon } from './assets/bank.svg?react';
import { ReactComponent as CalendarIcon } from './assets/calendar.svg?react';
import { ReactComponent as CarIcon } from './assets/car.svg?react';
import { ReactComponent as CheckmarkIcon } from './assets/checkmark.svg?react';
import { ReactComponent as CheckmarkFilledIcon } from './assets/checkmark--filled.svg?react';
import { ReactComponent as ChevronDownIcon } from './assets/chevron--down.svg?react';
import { ReactComponent as ChevronLeftIcon } from './assets/chevron--left.svg?react';
import { ReactComponent as ChevronRightIcon } from './assets/chevron--right.svg?react';
import { ReactComponent as ChevronUpIcon } from './assets/chevron--up.svg?react';
import { ReactComponent as CloseIcon } from './assets/close.svg?react';
import { ReactComponent as CompanyIcon } from './assets/company.svg?react';
import { ReactComponent as CopyIcon } from './assets/copy.svg?react';
import { ReactComponent as CurrencyIcon } from './assets/currency.svg?react';
import { ReactComponent as CurrencyPundIcon } from './assets/currency-pund.svg?react';
import { ReactComponent as DataEnrichmentIcon } from './assets/data-enrichment.svg?react';
import { ReactComponent as DnsServicesIcon } from './assets/dns-services.svg?react';
import { ReactComponent as EditIcon } from './assets/edit.svg?react';
import { ReactComponent as FlightInternationalIcon } from './assets/flight--international.svg?react';
import { ReactComponent as FxIcon } from './assets/fx.svg?react';
import { ReactComponent as GiftIcon } from './assets/gift.svg?react';
import { ReactComponent as GrowthIcon } from './assets/growth.svg?react';
import { ReactComponent as HealthCrossIcon } from './assets/health-cross.svg?react';
import { ReactComponent as HomeIcon } from './assets/home.svg?react';
import { ReactComponent as GaugeIcon } from './assets/gauge.svg?react';
import { ReactComponent as InformationIcon } from './assets/information.svg?react';
import { ReactComponent as InformationFilledIcon } from './assets/information--filled.svg?react';
import { ReactComponent as LicenseThirdPartyFinancialProfileIcon } from './assets/license--third-partyFinancial profile.svg?react';
import { ReactComponent as MisuseIcon } from './assets/misuse.svg?react';
import { ReactComponent as MobileIcon } from './assets/mobile.svg?react';
import { ReactComponent as MoneycreditCardIcon } from './assets/moneycredit card.svg?react';
import { ReactComponent as MortageIcon } from './assets/mortage.svg?react';
import { ReactComponent as MovingTruckIcon } from './assets/moving-truck.svg?react';
import { ReactComponent as NotificationIcon } from './assets/notification.svg?react';
import { ReactComponent as NotificationNewIcon } from './assets/notification--new.svg?react';
import { ReactComponent as PiggyBankIcon } from './assets/piggy-bank.svg?react';
import { ReactComponent as ShareIcon } from './assets/share.svg?react';
import { ReactComponent as TrashCanIcon } from './assets/trash-can.svg?react';
import { ReactComponent as UpdateNowIcon } from './assets/update-now.svg?react';
import { ReactComponent as ViewIcon } from './assets/view.svg?react';
import { ReactComponent as ViewOffIcon } from './assets/view--off.svg?react';
import { ReactComponent as VisaIcon } from './assets/visa.svg?react';
import { ReactComponent as WarningAltFilledIcon } from './assets/warning--alt--filled.svg?react';
import { ReactComponent as WarningFilledIcon } from './assets/warning--filled.svg?react';
import { ReactComponent as WidgetsDownIcon } from './assets/widgets--down-icon.svg?react';
import { ReactComponent as LinkIcon } from './assets/link.svg?react';
import { ReactComponent as UserAdminIcon } from './assets/user--admin.svg?react';
import { ReactComponent as TrophyIcon } from './assets/trophy.svg?react';
import { ReactComponent as NeedIcon } from './assets/need.svg?react';
import { ReactComponent as LogoutIcon } from './assets/logout.svg?react';
import { ReactComponent as SwitchIcon } from './assets/switch.svg?react';
import { ReactComponent as SwitchVerticalIcon } from './assets/switch-vertical.svg?react';

import styled from 'styled-components';

const icons = {
  add: AddIcon,
  'arrow--left': ArrowLeftIcon,
  'arrow--down-left': ArrowDownLeftIcon,
  'arrow--down-right': ArrowDownRightIcon,
  'widgets--down-icon': WidgetsDownIcon,
  'arrow--up-left': ArrowUpLeftIcon,
  'arrow--up-right': ArrowUpRightIcon,
  bank: BankIcon,
  calendar: CalendarIcon,
  car: CarIcon,
  checkmark: CheckmarkIcon,
  'checkmark--filled': CheckmarkFilledIcon,
  'chevron--down': ChevronDownIcon,
  'chevron--left': ChevronLeftIcon,
  'chevron--right': ChevronRightIcon,
  'chevron--up': ChevronUpIcon,
  close: CloseIcon,
  company: CompanyIcon,
  copy: CopyIcon,
  currency: CurrencyIcon,
  'currency-pund': CurrencyPundIcon,
  'data-enrichment': DataEnrichmentIcon,
  'dns-services': DnsServicesIcon,
  edit: EditIcon,
  'flight--international': FlightInternationalIcon,
  fx: FxIcon,
  gift: GiftIcon,
  growth: GrowthIcon,
  'health-cross': HealthCrossIcon,
  home: HomeIcon,
  gauge: GaugeIcon,
  information: InformationIcon,
  'information--filled': InformationFilledIcon,
  'license--third-partyFinancial profile': LicenseThirdPartyFinancialProfileIcon,
  misuse: MisuseIcon,
  mobile: MobileIcon,
  'moneycredit card': MoneycreditCardIcon,
  mortage: MortageIcon,
  'moving-truck': MovingTruckIcon,
  notification: NotificationIcon,
  'notification--new': NotificationNewIcon,
  'piggy-bank': PiggyBankIcon,
  share: ShareIcon,
  'trash-can': TrashCanIcon,
  'update-now': UpdateNowIcon,
  view: ViewIcon,
  'view--off': ViewOffIcon,
  visa: VisaIcon,
  'warning--alt--filled': WarningAltFilledIcon,
  link: LinkIcon,
  'user--admin': UserAdminIcon,
  trophy: TrophyIcon,
  'warning--filled': WarningFilledIcon,
  need: NeedIcon,
  logout: LogoutIcon,
  switch: SwitchIcon,
  switchVertical: SwitchVerticalIcon
};

const IconContainer = styled.span`
  display: flex;
  align-items: center;
  ${(props) => (props.onClick ? 'cursor: pointer;' : '')};

  svg {
    width: ${(props) => `${props.size}`}px;
    height: ${(props) => `${props.size}`}px;
  }

  #Vector {
    fill: ${(props) => `${props.color}`};
  }
`;

const Icon = ({ name, color = '#050A25', size = 20, className, style, onClick }) => {
  const Component = icons[name];

  if (!Component) return null;

  return (
    <IconContainer color={color} size={size} className={className} style={style} onClick={onClick}>
      <Component />
    </IconContainer>
  );
};

export default Icon;
