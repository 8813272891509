import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHandHoldingUsd,
  faCreditCard,
  faLandmark,
  faUserCircle,
  faArrowRightArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/react';

import { WebSocketContextProvider } from 'hooks/useWebSocket';
import { GTMContextProvider } from '@ui/hooks/useGTM';
import { CustomerIoContextProvider } from '@ui/hooks/useCustomerIo';
import { FacebookPixelContextProvider } from '@ui/hooks/useFacebookPixel';
import { UserSelectorContextProvider } from 'hooks/useUser';
import { LanguageContextProvider } from '@ui/hooks/useLanguage';
import App from './App';
import GlobalStyles from './global-styles';
import reportWebVitals from './reportWebVitals';
import { AlertsContextProvider } from './hooks/useAlerts';
import { FlagsContextProvider } from './hooks/useFlags';

import '@ui/styles/global.css';
// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Bootstrap Bundle JS
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { theme } from './theme';

import './i18n/config';
import { HotjarContextProvider } from '@ui/hooks/useHotjar';
import { TrackingContextProvider } from '@ui/hooks/useTracking';

// fonts
import '@ui/fonts/Gilroy/Gilroy-Bold.ttf';
import '@ui/fonts/Gilroy/Gilroy-Heavy.ttf';
import '@ui/fonts/Gilroy/Gilroy-Light.ttf';
import '@ui/fonts/Gilroy/Gilroy-Medium.ttf';
import '@ui/fonts/Gilroy/Gilroy-Regular.ttf';

import '@ui/components/styles.css';
import '@ui/components/Colors/style.css';
import '@ui/components/Fonts/style.css';
import NetworkStatus from '@ui/components/NetworkStatus';

const root = ReactDOM.createRoot(document.getElementById('root'));

// !Due to the webpack5 migration and the polyfills required to have process.env
// !use REACT_APP_ENV instead on NODE_ENV

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

library.add(faHandHoldingUsd, faCreditCard, faLandmark, faUserCircle, faArrowRightArrowLeft);

if (process.env.REACT_APP_ENV === 'prod') {
  Sentry.init({
    dsn: 'https://e0ecc61b81e776816938a8cffb6dab30@o4508227830022144.ingest.de.sentry.io/4508291747610704',
    integrations: [],
    environment: process.env.REACT_APP_ENV
  });
}

root.render(
  <>
    <GlobalStyles />
    <BrowserRouter>
      <LanguageContextProvider>
        <GTMContextProvider>
          <CustomerIoContextProvider>
            <FacebookPixelContextProvider>
              <HotjarContextProvider>
                <TrackingContextProvider>
                  <UserSelectorContextProvider>
                    <WebSocketContextProvider>
                      <FlagsContextProvider>
                        <QueryClientProvider client={queryClient}>
                          <ThemeProvider theme={theme}>
                            <AlertsContextProvider>
                              <NetworkStatus>
                                <App />
                              </NetworkStatus>
                            </AlertsContextProvider>
                          </ThemeProvider>
                        </QueryClientProvider>
                      </FlagsContextProvider>
                    </WebSocketContextProvider>
                  </UserSelectorContextProvider>
                </TrackingContextProvider>
              </HotjarContextProvider>
            </FacebookPixelContextProvider>
          </CustomerIoContextProvider>
        </GTMContextProvider>
      </LanguageContextProvider>
    </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
